import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import { 
    Container, 
    Typography,
    Grid, 
    Card,
    CardHeader,
    CardContent,
    Button,
    IconButton,
    Paper
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

// react pdf
// import {DownloadNOAP, ViewNOAP} from "../../reports/NOAP";
import {DownloadUSePATPermit, ViewNOAP} from "../../reports/USePATPermit";

// actions
import { 
    getApplicantPassportPhoto
} from "../../redux/actions/applicant";

const useStyles = makeStyles ({
    formHeader: {
        fontFamily: `"Quicksand", sans-serif`,
        fontWeight: 500,
        color:  `#fff`,  //`#d58a13`, //"#707070",
        marginTop: `15px`,
        marginBottom: `20px`,
        width: `100%`,
        background: `rgb(215, 93, 95) none repeat scroll 0% 0%`
    },
    resultMsg: {
        fontFamily: `"Quicksand", "Roboto", "Helvetica", "Arial", sans-serif`,
        fontWeight: 400,
        lineHeight: 1.5,
        color: 'black'
    },
    resultRemarks: {
        backgroundColor: `#32bd32`,
        color: `#fff`,
        width: `50%`,
        textAlign: `center`,
        margin: `10px auto`
    },
    resultRemarksWaitlist: {
        backgroundColor: `#e3b93d`,
        color: `#fff`,
        width: `50%`,
        textAlign: `center`,
        margin: `10px auto`
    },
    resultRemarksWaivedSlot: {
        backgroundColor: `maroon`,
        color: `#fff`,
        width: `50%`,
        textAlign: `center`,
        margin: `10px auto`
    },
    inlineTextEmphasize: {
        fontWeight: `bold`
    },
    inlineTextEmphasizeWarning: {
        fontWeight: `bold`,
        color: `maroon`
    }
});



const ApplicationTrackingDetails = (props) => {

    const classes = useStyles();

    const applicant = useSelector(state => state.applicant);

    const resultMsgPending = (
        <Typography variant="body" className={classes.resultMsg}>
            We have received your submitted application. 
            Please check the status of your application 7 days after the date of filing.
            <br/><br/>
            Thank you.
            <br/><br/>
        </Typography>
    );

    const USePAT_DATA = {
        date: new Date().toLocaleString('en-US', {
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            month: 'long', // numeric, 2-digit, long, short, narrow
         }),
         appNo: props.applicationStatus.appNo,
         name: props.applicationStatus.applicantName,
         passportPhoto: applicant.passportPhoto ? applicant.passportPhoto.fileSrc : '',
         college: props.applicantCollege,
         campus: props.applicantCampus,
         program: `${props.applicationStatus.Choice1_CourseName} ${props.applicationStatus.Choice1_CourseMajor ? `major in ${props.applicationStatus.Choice1_CourseMajorName}` : ''}`,
         yearLevel: "1st year",
         semester: "1st Semester",
         academicYear: "2021-2022",
         testDate: props.applicationStatus.testDate,
         testTime: props.applicationStatus.testTime,
         testCenter: props.applicationStatus.testCenterName,
         testRoom: props.applicationStatus.testRoomName,
         schoolLastAttended: props.applicationStatus.SchoolLastAttended
    }

   
    useEffect(() => {
        if(props.applicationStatus && props.applicationStatus.hasSelectedSchedule === 'true'){
            getApplicantPassportPhoto({
                appNo: props.applicationStatus.appNo,
                fileID: 6
            });
        }
    }, [props.applicationStatus])


    return (
        <div>
            {/* <Container maxWidth="md" style={{marginBottom:"15px"}}> */}

            {/* Result Details*/}
            {props.applicationStatus.isFound ? 
                <Grid item container xs={12} sm={12}>
                    <div className={classes.formHeader}><Typography variant="h6" style={{width:"100%",textAlign:"center"}}>Application Status</Typography></div>
                    {/* <Grid item container xs={12} sm={5}>
                        <Paper style={{minHeight:"150px",minWidth:"50%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
                            <IconButton  style={{fontSize:"1.2rem"}}>
                                <CameraAltIcon />
                            </IconButton>
                            <img src={props.applicantProfilePhoto ? props.applicantProfilePhoto.fileSrc : ''} width="50%"  display="block" />
                        </Paper>
                    </Grid> */}

                    <Grid item xs={12} sm={12} style={{padding:'0 10px'}}>
                        {props.applicationStatus.validationStatus === 'Validated' ? 
                            <Typography className={classes.resultRemarks} style={{fontSize:'1.0rem',fontWeight:"bold"}}>VALIDATED</Typography> 
                        : null }
                        {props.applicationStatus.validationStatus === 'Pending Validation' ? <Typography className={classes.resultRemarksWaitlist} style={{fontSize:'1.0rem',fontWeight:"bold"}}>PENDING VALIDATION</Typography> : null }
                        {props.applicationStatus.validationStatus === 'Invalid Application' ? 
                            <React.Fragment>
                                <Typography className={classes.resultRemarksWaivedSlot} style={{fontSize:'1.0rem',fontWeight:"bold"}}>INVALID APPLICATION</Typography> 
                                <Typography style={{marginTop:'10px', marginBottom:'30px'}}>
                                        {props.applicationStatus.invalidatedRemarks.remarks ? props.applicationStatus.invalidatedRemarks.remarks : null} 
                                </Typography>
                            </React.Fragment>

                            : null   
                        }
                        <Typography style={{fontSize:'1.15rem'}}>{props.applicationStatus.appNo}</Typography>
                        <Typography style={{fontSize:'1.15rem',fontWeight:'bold'}}>{props.applicationStatus.applicantName}</Typography>
                        <Typography style={{fontSize:'0.9rem',margin:'10px auto'}}>{props.applicationStatus.Choice1_CourseName} {props.applicationStatus.Choice1_CourseMajor ? ` | ${props.applicationStatus.Choice1_CourseMajorName}` : ''}</Typography>
                        {/* <Typography style={{fontSize:'0.9rem',margin:'0px auto'}}>{props.applicantCollege}</Typography>
                        <Typography style={{fontSize:'0.9rem',margin:'0px auto'}}>{props.applicantCampus}</Typography> */}
                        {props.applicationStatus.validationStatus === 'Validated' && props.applicationStatus.hasSelectedSchedule === 'true' ?
                            <React.Fragment>
                                <Typography>
                                    Admission Test Schedule: 
                                </Typography>
                                <Typography style={{fontSize:'1.15rem',fontWeight:'bold'}}>
                                    {props.applicationStatus.testDate} {props.applicationStatus.testTime}
                                </Typography>
                                <Typography style={{marginTop:'10px'}}>
                                    Please download your Permit to Take the USePAT document. 
                                    You will need to present a printed copy of this document prior to taking the admission
                                    test.  
                                </Typography>
                            </React.Fragment>
                            : null
                        }
                        {props.applicationStatus.validationStatus === 'Validated' && applicant.passportPhoto && props.applicationStatus.hasSelectedSchedule === 'true' ? 
                            <div style={{marginTop:'10px'}}><DownloadUSePATPermit data={USePAT_DATA}/></div> 
                            : null 
                        }
                    </Grid>
                
                {/* Result Message */}
                <Grid item xs={12} sm={12} style={{marginTop:"20px"}}>
                    {/* {props.applicantStatus === 'Qualified' && !props.applicantIsEnlisted && props.resultsCheckingPeriod === 1 ? resultMsgQualified : null} */}
                    {props.applicationStatus.validationStatus === 'Pending Validation' ? resultMsgPending : null}
                    
                </Grid>
            </Grid>
            : null }

            {/* Application Not Found Message */}
            {!props.applicationStatus.isFound  ? 
                <Grid item container xs={12} sm={12}>
                    {/* Result Message */}
                    <Grid item xs={12} sm={12} style={{marginTop:"20px"}}>
                        <Typography>Application Not Found.</Typography>
                    </Grid>
                </Grid>
            : null}

            {/* </Container> */}
        </div>
    );
};

export default ApplicationTrackingDetails;